import { Scalars, TimeWindow } from "@api/graphql/generated/generated-types";
import { dayAbbreviations } from "@src/orders/create/utils/DateConstants";
import moment from "moment-timezone";

export function currentEpochSeconds(): number {
  return Math.floor(Date.now() / 1000);
}

export function minutesAfterCurrentEpochSeconds(minutes: number): number {
  return currentEpochSeconds() + minutes * 60;
}

export function makeDateFromEpochSeconds(epochSeconds: number): Date {
  return new Date(epochSeconds * 1000);
}

export const DATE_FORMAT = "MMM Do, YYYY";
export const DATE_FORMAT_WITH_DAY = "ddd, MMM Do";
export const DATE_TIME_FORMAT = "h:mma MMM Do";
export const TIME_FORMAT = "h:mm a";

export function formatDate(date: Date | null | undefined): string {
  if (!date) {
    return "";
  }
  return moment(date).format(DATE_FORMAT);
}

export function formatDateWithDay(date: Date | null | undefined): string {
  if (!date) {
    return "";
  }
  return moment(date).format(DATE_FORMAT_WITH_DAY);
}

export function formatDateTime(date: Date): string {
  return moment(date).format(DATE_TIME_FORMAT);
}

export function formatTime(date: Date | undefined): string {
  if (!date) {
    return "";
  }
  return moment(date).format(TIME_FORMAT);
}

export function formatTimeFromTimestampScalar(
  timestamp: Scalars["Timestamp"]
): string {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp * 1000);
  return formatTime(date);
}

export function formatDateTimeFromTimestampScalar(
  timestamp: Scalars["Timestamp"]
): string {
  if (!timestamp) {
    return "";
  }
  const date = new Date(timestamp * 1000);
  return formatDateTime(date);
}

export function isToday(input: Date): boolean {
  return moment().isSame(input, "day");
}

export function isDateStringToday(input: Scalars["Date"]) {
  return moment(new Date()).format("YYYY-MM-DD") === input;
}

export function toDate(
  dateString: Scalars["Date"] | undefined
): Date | undefined {
  if (!dateString) {
    return undefined;
  }
  const parts = dateString.split("-");

  // Please note that months are 0-based in JavaScript.
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // subtract 1 to get month index (0-11)
  const day = parseInt(parts[2], 10);

  return new Date(year, month, day);
}

export function formatTimestamp(timestamp: number): string {
  if (!timestamp) {
    return "";
  }
  // Convert the timestamp to a Date object
  const date = new Date(timestamp * 1000);

  // Format the date object to "hh:mm A" format
  const formattedTime = new Intl.DateTimeFormat("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }).format(date);

  return formattedTime;
}

export function formatTimeWindow(timeWindow: TimeWindow): string {
  const { open, close } = timeWindow;

  if (open && close) {
    return `${formatTimestamp(open)} - ${formatTimestamp(close)}`;
  }

  if (open && !close) {
    return `Ready by ${formatTimestamp(open)}`;
  }

  if (!open && close) {
    return `Due at ${formatTimestamp(close)}`;
  }

  return "Ready now";
}

const SECONDS_IN_DAY = 86400;

export function getSecondsSinceMidnightUtc(isoDateTime) {
  const date = new Date(isoDateTime);
  let secondsSinceMidnightUtc =
    date.getUTCHours() * 3600 + // Hours to seconds
    date.getUTCMinutes() * 60 + // Minutes to seconds
    date.getUTCSeconds(); // Seconds

  const localDay = date.getDate();
  const utcDay = date.getUTCDate();

  if (localDay !== utcDay) {
    if (date.getTimezoneOffset() > 0) {
      secondsSinceMidnightUtc += SECONDS_IN_DAY;
    } else {
      secondsSinceMidnightUtc -= SECONDS_IN_DAY;
    }
  }

  return secondsSinceMidnightUtc;
}

export function incrementDays(date: Date, days: number): Date {
  let newDate = new Date(date.getTime());
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

export function isSameDay(date1, date2) {
  if (date1 === undefined || date2 === undefined) {
    return false;
  }
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const formatWeekDays = (days: string[]) =>
  days.map((day) => dayAbbreviations[day] || day).join(", ");

export function getExactMomentForSecondsSinceMidnightUtc(
  secondsSinceMidnightUtc: number
): Date {
  const today = moment().utc().startOf("day");
  const exactMoment = today.add(secondsSinceMidnightUtc, "seconds");
  return exactMoment.toDate();
}

export const getTimeInSecondsSinceMidnight = (timeInput: string): number => {
  // Parse the time input as an ISO 8601 string
  const time = moment(timeInput);

  // Get the start of the day for the given time
  const startOfDay = time.clone().startOf("day");

  // Calculate the difference in seconds from the start of the day
  const secondsSinceMidnight = time.diff(startOfDay, "seconds");

  return secondsSinceMidnight;
};

export const formatTimeFromSecondsSinceMidnight = (
  secondsSinceMidnight: number
): string => {
  const time = moment().startOf("day").add(secondsSinceMidnight, "seconds");
  return time.format("hh:mm A");
};

export const convertSecondsToDateTime = (
  timeZoneId: string,
  secondsSinceMidnight: number
): Date =>
  moment
    .tz(timeZoneId)
    .startOf("day")
    .add(secondsSinceMidnight, "seconds")
    .toDate();

export const formatTimeZone = (timeZoneId: string): string => {
  // Create a moment instance for the current time in the given timezone
  const nowInTimeZone = moment.tz(timeZoneId);

  // Return a formatted string with the timezone abbreviation
  // e.g., "Eastern Standard Time (EST)"
  return `${nowInTimeZone.format("z")}`;
};

export function timeStringToSeconds(timeString: string): number {
  const time = moment(timeString, "HH:mm");
  const secondsSinceMidnight = time.hours() * 3600 + time.minutes() * 60;
  return secondsSinceMidnight;
}

export function formatSecondsToTimeString(
  seconds: number | null | undefined
): string | undefined {
  if (seconds == null) return undefined;
  return moment.utc(seconds * 1000).format("HH:mm");
}
