import {
  DatePicker as BaseDatePicker,
  DatepickerProps,
} from "baseui/datepicker";

const DatePicker: React.FC<DatepickerProps> = (props) => {
  return (
    <BaseDatePicker
      {...props}
      overrides={{
        Input: {
          props: {
            overrides: {
              Input: {
                style: {
                  backgroundColor: "rgb(249 250 251)",
                },
              },
            },
          },
        },
        ...props.overrides,
      }}
    />
  );
};

export default DatePicker;
