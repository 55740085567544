import type { ComponentProps, FC, PropsWithChildren, ReactNode } from "react";
import Link from "next/link";
import classNames from "classnames";
import { useState } from "react";

export interface NavItemProps
  extends PropsWithChildren,
    Omit<ComponentProps<"div">, "text"> {
  active?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  solidIcon?: ReactNode;
  label: ReactNode;
  href: string;
  badgeCount?: number;
}

const NavItem: FC<NavItemProps> = ({
  href,
  icon,
  solidIcon,
  label,
  active = false,
  className,
  badgeCount = 0,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const commonClasses = "flex items-center justify-center pb-1";
  const iconToDisplay = active || isHovered ? solidIcon : icon;

  const anchorProps = {
    className: classNames(
      "flex py-1 px-4 rounded-full",
      {
        "hover:bg-slate-200": !active,
        "text-primary-600": active,
      },
      className
    ),
    onMouseEnter: () => setIsHovered(true),
    onMouseLeave: () => setIsHovered(false),
    ...props,
  };

  return (
    <Link href={href}>
      {/* @ts-ignore */}
      <a {...anchorProps} style={{ height: 40 }}>
        <span
          className={classNames(commonClasses, {
            "text-primary-600": isHovered,
          })}
        >
          {iconToDisplay}
        </span>
        <span
          className={classNames("ml-1 relative", {
            "text-primary-600 font-medium": active,
            "text-primary-600": isHovered,
            "flex flex-col justify-center": true,
          })}
        >
          {label}
          {badgeCount > 0 && (
            <span className="absolute -top-1 -right-3 inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-600 rounded-full">
              {badgeCount}
            </span>
          )}
        </span>
      </a>
    </Link>
  );
};

NavItem.displayName = "Nav.Item";
export default NavItem;
