import { ReactNode } from "react";
import { StateCreator } from "zustand";

export interface ToastType {
  message: string;
  icon?: ReactNode | null;
  type: "success" | "error" | "info" | "warning" | null;
  position?: "top" | "bottom" | null;
}

export interface ToastSlice {
  toast: ToastType | null;
  showToast: (toast: ToastType | null, duration?: number) => void;
}

export const createToastSlice: StateCreator<ToastSlice> = (set) => ({
  toast: null,
  showToast: (toast, duration = 3000) => {
    set({ toast });
    setTimeout(() => set({ toast: null }), duration);
  },
});
