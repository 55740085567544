import React, { createContext, useContext, useEffect, useState } from "react";
import { ShipmentsPrimaryFilterType } from "../types";
import { EndCustomer } from "@api/graphql/generated/generated-types";
import { useLocalStorage } from "usehooks-ts";

// Define the context interface
interface ShipmentFiltersContextProps {
  dateFilter: Date;
  setDateFilter: (date: Date) => void;
  minDate: Date | undefined;
  setMinDate: (date: Date) => void;
  maxDate: Date | undefined;
  setMaxDate: (date: Date) => void;
  selectedPrimaryFilter: ShipmentsPrimaryFilterType;
  setSelectedPrimaryFilter: (filter: ShipmentsPrimaryFilterType) => void;
  selectedDriverIdFilter: string | undefined;
  setSelectedDriverIdFilter: (driverId: string | undefined) => void;
  selectedCustomer: EndCustomer | undefined;
  setSelectedCustomer: (customer: EndCustomer | undefined) => void;
  trackingNumbersFilter: string;
  setTrackingNumbersFilter: (trackingNumbers: string) => void;
  displayIdsFilter: number | undefined;
  setDisplayIdsFilter: (displayIds: number | undefined) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

// the context with a default value
const ShipmentFiltersContext =
  createContext<ShipmentFiltersContextProps | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

// the context provider
const ShipmentFiltersProvider = ({ children }: Props) => {
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [minDate, setMinDate] = useState<Date | undefined>(new Date());
  const [maxDate, setMaxDate] = useState<Date | undefined>(new Date());
  const [selectedPrimaryFilter, setSelectedPrimaryFilter] =
    useLocalStorage<ShipmentsPrimaryFilterType>(
      "selectedPrimaryFilter",
      ShipmentsPrimaryFilterType.ACTIVE
    );
  const [selectedDriverIdFilter, setSelectedDriverIdFilter] =
    useState<string | undefined>(undefined);
  const [selectedCustomer, setSelectedCustomer] =
    useState<EndCustomer | undefined>(undefined);
  const [trackingNumbersFilter, setTrackingNumbersFilter] =
    useState<string>("");
  const [displayIdsFilter, setDisplayIdsFilter] =
    useState<number | undefined>(undefined);
  const [searchTerm, setSearchTerm] = React.useState("");

  useEffect(() => {
    if (
      trackingNumbersFilter.length > 3 ||
      displayIdsFilter !== undefined ||
      selectedCustomer !== undefined
    ) {
      setMinDate(undefined);
      setMaxDate(undefined);
    }
  }, [displayIdsFilter, trackingNumbersFilter]);

  // Effect for handling changes in searchTerm
  useEffect(() => {
    if (searchTerm.length > 0) {
      setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.ALL);
      setMinDate(undefined);
      setMaxDate(undefined);
      setTrackingNumbersFilter("");
      setDisplayIdsFilter(undefined);
    } else {
      const currentDate = new Date();
      setMinDate(currentDate);
      setMaxDate(currentDate);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (selectedPrimaryFilter !== ShipmentsPrimaryFilterType.ALL) {
      if (
        trackingNumbersFilter.length > 3 ||
        displayIdsFilter !== undefined ||
        selectedCustomer !== undefined
      ) {
        setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.ALL);
      }
    }
  }, [
    trackingNumbersFilter,
    displayIdsFilter,
    selectedCustomer,
    selectedPrimaryFilter,
  ]);

  useEffect(() => {
    if (selectedPrimaryFilter !== ShipmentsPrimaryFilterType.ACTIVE) {
      setSelectedDriverIdFilter(undefined);
    }
  }, [selectedPrimaryFilter]);

  useEffect(() => {
    if (
      selectedDriverIdFilter &&
      selectedPrimaryFilter === ShipmentsPrimaryFilterType.UNASSIGNED
    ) {
      setSelectedPrimaryFilter(ShipmentsPrimaryFilterType.ACTIVE);
    }
  }, [selectedDriverIdFilter]);

  return (
    <ShipmentFiltersContext.Provider
      value={{
        dateFilter,
        setDateFilter,
        minDate,
        setMinDate,
        maxDate,
        setMaxDate,
        selectedPrimaryFilter,
        setSelectedPrimaryFilter,
        selectedDriverIdFilter,
        setSelectedDriverIdFilter,
        selectedCustomer,
        setSelectedCustomer,
        trackingNumbersFilter,
        setTrackingNumbersFilter,
        displayIdsFilter,
        setDisplayIdsFilter,
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </ShipmentFiltersContext.Provider>
  );
};

// the custom hook
const useShipmentFiltersContext = () => {
  const context = useContext(ShipmentFiltersContext);
  if (context === undefined) {
    throw new Error(
      "useShipmentFiltersContext must be used within a ShipmentFiltersProvider"
    );
  }
  return context;
};

export { ShipmentFiltersProvider, useShipmentFiltersContext };
